.App {
  text-align: center;
  min-height: 100vh;
  background-color: var(--bg-color);
  padding: 0;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
}

/* Light theme variables (default) */
:root {
  --bg-color: #f5f5f5;
  --text-color: #333;
  --card-bg: white;
  --card-shadow: rgba(0, 0, 0, 0.1);
  --price-color: #000000;
}

/* Dark theme variables */
[data-theme='dark'] {
  --bg-color: #000000;
  --text-color: #ffffff;
  --card-bg: #1a1a1a;
  --card-shadow: rgba(0, 0, 0, 0.5);
  --price-color: #ffffff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 160px); /* Account for header and footer */
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }
}

h1 {
  color: var(--text-color);
  margin-bottom: 40px;
  font-size: 2rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }
}

.price-cards {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.price-card {
  background: var(--card-bg);
  border-radius: 10px;
  padding: 20px;
  min-width: 280px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 4px 6px var(--card-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  padding-top: 40px;
}

.oracle-name {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  opacity: 0.8;
}

@media (max-width: 480px) {
  .price-card {
    min-width: 100%;
    max-width: 100%;
  }
}

.chain-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--card-bg);
}

h2 {
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: var(--price-color);
  transition: color 0.3s ease;
}

.error {
  color: #e53e3e;
  padding: 20px;
  background: #fff5f5;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 500px;
}

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  transition: background-color 0.3s ease;
  position: sticky;
  top: 0;
  z-index: 1000;
}

@media (max-width: 480px) {
  .site-header {
    padding: 0.75rem 1rem;
  }
}

.logo {
  font-size: 32px;
  font-weight: bold;
}

@media (max-width: 480px) {
  .logo {
    font-size: 24px;
  }

  .theme-toggle {
    padding: 6px 12px;
  }
}

.theme-toggle {
  padding: 8px 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.theme-toggle:active {
  transform: translateY(0);
}

.search-container {
  margin: 32px auto;
  max-width: 800px;
  padding: 0 24px;
  width: calc(100% - 48px);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: start;
}

.feed-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.feed-search {
  padding: 12px 48px 12px 16px;
  border: 2px solid var(--input-border);
  border-radius: 8px;
  font-size: 16px;
  background: var(--input-bg);
  color: var(--text-color);
  transition: all 0.2s ease;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.feed-search:hover, .chain-search:hover {
  border-color: var(--input-border-hover);
}

.feed-search:focus, .chain-search:focus {
  border-color: var(--input-border-focus);
  box-shadow: 0 0 0 3px var(--input-shadow);
}

.feed-search::placeholder, .chain-search::placeholder {
  color: var(--placeholder-color);
}

.search-container button {
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: var(--button-text-color, white);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 100%;
  white-space: nowrap;
}

.search-container button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.search-container button:active {
  transform: translateY(0);
}

.chain-selector {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.chain-button {
  background-color: transparent !important;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.chain-button::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid var(--text-color);
  margin-left: 2px;
  transition: transform 0.2s ease;
}

.chain-button.open::after {
  transform: rotate(180deg);
}

.chain-logo-button {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.chain-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 280px;
  background: var(--card-bg);
  border: 2px solid var(--input-border);
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 4px 12px var(--card-shadow);
  transition: all 0.2s ease;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar) transparent;
  box-sizing: border-box;
}

.chain-dropdown::-webkit-scrollbar {
  width: 8px;
}

.chain-dropdown::-webkit-scrollbar-track {
  background: transparent;
}

.chain-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 4px;
}

.chain-option {
  padding: 12px 16px;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chain-search-container {
  padding: 12px;
  border-bottom: 1px solid var(--border-color);
}

.chain-search {
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--input-border);
  background: var(--input-bg);
  color: var(--text-color);
}

.chain-option {
  padding: 8px 12px;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
}

.chain-option:hover {
  background: var(--hover-bg);
}

.chain-option.selected {
  background: var(--selected-bg);
  color: var(--selected-text);
}

.chain-id {
  color: var(--placeholder-color);
  font-size: 0.9em;
}

.chain-option.no-results {
  color: var(--placeholder-color);
  font-style: italic;
  cursor: default;
}

.chain-option:hover.no-results {
  background: none;
}

button:disabled {
  background-color: var(--placeholder-color);
  cursor: not-allowed;
  transform: none !important;
}

@media (max-width: 768px) {
  .search-container {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 0 12px;
    width: calc(100% - 24px);
  }
  
  .feed-search-wrapper {
    position: relative;
  }

  .feed-search {
    padding-right: 40px;
  }
  
  .chain-selector {
    min-width: unset;
    right: 4px;
  }

  .chain-button {
    padding: 2px;
  }

  .chain-logo-button {
    width: 24px;
    height: 24px;
  }

  .chain-dropdown {
    width: 240px;
    right: -8px;
  }

  .search-container button {
    width: 100%;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .chain-dropdown {
    width: 200px;
  }
}

/* Footer styles */
.site-footer {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  position: relative;
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
}

.copyright {
  font-size: 12px;
}

.footer-links {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

@media (max-width: 480px) {
  .site-footer {
    padding: 1rem;
  }

  .copyright {
    font-size: 10px;
  }

  .footer-links {
    gap: 12px;
    font-size: 12px;
  }
}

/* Additional theme variables */
:root {
  --button-text-color: white;
  --input-border: #e2e8f0;
  --input-border-hover: #cbd5e0;
  --input-border-focus: #3182ce;
  --input-shadow: rgba(49, 130, 206, 0.2);
  --input-bg: white;
  --primary-color: #333333;
  --primary-hover: #000000;
  --placeholder-color: #a0aec0;
  --scrollbar: #cbd5e0;
  --hover-bg: #f7fafc;
  --selected-bg: #ebf8ff;
  --selected-text: #2b6cb0;
  --border-color: #e2e8f0;
}

[data-theme='dark'] {
  --button-text-color: #000000;
  --input-border: #333333;
  --input-border-hover: #444444;
  --input-border-focus: #666666;
  --input-shadow: rgba(255, 255, 255, 0.1);
  --input-bg: #1a1a1a;
  --primary-color: #ffffff;
  --primary-hover: #cccccc;
  --placeholder-color: #666666;
  --scrollbar: #333333;
  --hover-bg: #222222;
  --selected-bg: #333333;
  --selected-text: #ffffff;
  --border-color: #333333;
}
